import React, { useEffect, useState } from "react";
import "../../styles/CometChatLogin/CometChatLogin.css";
import { useNavigate } from "react-router-dom";
import {
  CometChatUIKit,
  CometChatUIKitLoginListener,
} from "@cometchat/chat-uikit-react";
import { COMETCHAT_CONSTANTS } from "../../AppConstants";
import { Oval } from "react-loader-spinner";

const getBrowserTheme = (): "light" | "dark" => {
  const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return isDarkTheme ? "dark" : "light";
};

const CometChatLogin = () => {
  const navigate = useNavigate();
  const [theme, setTheme] = useState<string>();

  useEffect(() => {
    const handleThemeChange = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? "dark" : "light");
    };
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setTheme(getBrowserTheme());
    mediaQuery.addEventListener("change", handleThemeChange);
    return () => {
      mediaQuery.removeEventListener("change", handleThemeChange);
    };
  }, []);

  // Fetch the authToken on component mount
  useEffect(() => {
    // Check if already logged in
    if (CometChatUIKitLoginListener.getLoggedInUser()) {
      navigate("/home", { replace: true });
    } else {
      // Get the authToken from the API
      fetch(COMETCHAT_CONSTANTS.AUTH_TOKEN_URL)
        .then((response) => response.json())
        .then((res) => {
          if (res.authToken) {
            // Use CometChat's loginWithAuthToken method to log the user in
            CometChatUIKit.loginWithAuthToken(res.authToken)
              .then((loggedInUser) => {
                console.log("Login successful, loggedInUser:", loggedInUser);
                navigate("/home"); // Navigate to the home page
              })
              .catch((error) => {
                console.error("Login failed:", error);
              });
          } else {
            console.error("No authToken received");
          }
        })
        .catch((error) => {
          console.error("Error fetching authToken:", error);
        });
    }
  }, [navigate]);

  return (
    <div className="cometchat-login__container" data-theme={theme}>
      <div className="centered-loader">
        <Oval
          visible={true}
          height="40"
          width="40"
          ariaLabel="Loading"
          wrapperClass="oval-animation"
        />
      </div>
    </div>
  );
};

export default CometChatLogin;
